<template>
  <div>
    <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Import Store File"/>
        <v-spacer></v-spacer>

        <!-- Edit instore driver Modal -->
        <v-dialog v-model="dialog" max-width="400px" class="pa-6">
          <v-card>
            <v-card-title>
              <span class="headline">แก้ไขข้อมูล</span>
            </v-card-title>
            <v-card-text>
              <v-alert
                dense
                outlined
                type="error"
                v-if="editOrder != ''" >
              Error :: {{ editOrder }} 
              </v-alert>
              <v-form v-model="form_valid" ref="form">
                <v-container>
                  <v-row>
                        <v-col cols="12" sm="12" md="12" class="pb-1 pt-1">
                            <h4>Store Name : {{ instore_selected.store_name }}</h4>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="pb-1 pt-1">
                            <h4>Driver : {{ instore_selected.driver }}</h4>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="pb-1 pt-1">
                            <h4>Order Time : {{ instore_selected.order_time }}</h4>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="pb-1 pt-1">
                            <h4>Customer Phone : {{ instore_selected.phone_cust }}</h4>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="pb-1 pt-1">
                            <h4>Id : {{ instore_selected.id }}</h4>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="pb-1 pt-1">
                            <h4>Rider Phone : {{ instore_selected.phonenumber }}</h4>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                              label="Order No."
                              :items="order_lists"
                              :item-text="'order_no'"
                              :item-value="'order_no'"
                              v-model="update_instore.selected_order"
                              no-data-text="Select.."
                              clearable
                              :search-input.sync="searchInput"
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                              label="Rider Phone"
                              :items="phone_lists"
                              :item-text="'phone'"
                              :item-value="'phone'"
                              v-model="update_instore.rider_phone"
                              no-data-text="Select.."
                              clearable
                              :search-input.sync="searchInputRiderPhone"
                          ></v-autocomplete>
                        </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="saveUpdateInstore">Submit</v-btn>
            </v-card-actions>
          </v-card>
           <!-- End Modal Content -->
        </v-dialog> 

      </v-toolbar> 
    <v-divider></v-divider>
    <div class="content-wrap">
        <v-toolbar class="search-tools pa-0 mb-4" flat>
          <v-col class="d-flex" cols="10" sm="10">
            <v-menu max-width="290">
              <template v-slot:activator="{ on }">
                <v-file-input
                    v-model="importItem.importFile"
                    :rules="[validate_rules.required,validate_rules.filesize,validate_rules.filetype]"
                    accept=".xlsx"
                    placeholder="Browse a xlsx file"
                    label="Instore driver"
                    v-on="on"
                ></v-file-input>
              </template>
            </v-menu>
          </v-col>
          <!-- <v-spacer></v-spacer> -->
          <v-btn class="mx-2" depressed dark small color="primary" v-show="stateimport == true" @click="checkImportFile(options);">
            Import
          </v-btn>

          <v-btn class="mx-2" depressed dark small color="primary" v-show="stateimport == false">
            <v-progress-circular
              :size="20"
              color="white"
              indeterminate
              class="mr-3"
            ></v-progress-circular>Import
          </v-btn>
          
        </v-toolbar>
        <v-alert
            dense
            outlined
            type="error"
            v-if="importItem.import_file_error != ''" >
        Error :: {{ importItem.import_file_error }} 
        </v-alert>

        <v-col class="d-flex" cols="4" sm="4" offset="8">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>

        <v-data-table 
            :headers="headers"
            :items="instore_datas" 
            :options.sync="options"
            item-key="Id"
            :search="search"
            :loading="loadingDataTable"
            :footer-props="{
              'items-per-page-options': [25, 100, 500]
            }"
            class="text-small"
            multi-sort
            no-data-text="There is no data"
        >

          <template v-slot:[`item.id`]="{item}">
            {{ item.match_employee ? item.match_employee.ssn : item.file_data.id }}
          </template>

          <template #item.updatedAt="{value}">
            {{ value | datetime}}
          </template>

          <template v-slot:[`item.driver`]="{item}">
            {{ item.match_employee ? item.match_employee.name_th : item.file_data.driver }}
          </template>
          
          <template v-slot:[`item.action`]="{item}">
            <div v-if="item.record_case != 9">
              <v-btn class="ma-1" color="primary" v-show="stateimport == true" small @click="updateInstoreImport(item)">
                <v-icon small>mdi mdi-pencil</v-icon>
                แก้ไข
              </v-btn>
            </div>
          </template>

        </v-data-table>
        <v-col class="text-right" v-show="instore_datas.length > 0">
          <v-btn class="mx-2" :disabled="btn_confirm" v-show="stateimport == true" depressed dark small color="primary" @click="saveInstoreData();">
            Confirm
          </v-btn>

          <v-btn class="mx-2" v-show="stateimport == false" depressed dark small color="primary">
            <v-progress-circular
              :size="20"
              color="white"
              indeterminate
              class="mr-3"
            ></v-progress-circular>Confirm
          </v-btn>
        </v-col>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import _ from 'lodash';

export default {
  name: 'InstoreDriver',
  components: {
      pageHeading
  },
  data: () => ({
    search: '',
    searchInput : '',
    searchInputRiderPhone : '',
    dialog: false,
    order_lists :[],
    phone_lists : [],
    importCheckResult : '',
    instore_selected : {
      store_name : null,
      driver : null,
      order_time : null,
      phone_cust : null,
      id : null,
      phonenumber : null
    },
    update_instore : {
      order_id : null,
      selected_order : '',
      rider_phone : null
    },
    default_update_instore : {
      order_id : null,
      selected_order : '',
      rider_phone : null
    },
    instore_datas :[],
    check_status : null,
    btn_confirm : true,
    import_id : null,
    stateimport : true,
    checkInterval : '',
    editOrder : '',
    importItem: {
        importFile : null,
        schedule_id : null,
        import_file_error : "",
        import_content : [],
        is_import_file_valid : false
    },
    form_valid : false,
    validate_rules : {
        required: v => !!v || 'This field is required',
        select2: v => {
          if(typeof v === 'object'){
            return (v.value !== '') || 'This field is required';
          } else {
            return true
          }
        },
        filesize:  v => !v || v.size < 2000000 || 'Updload size should be less than 2 MB!',
        filetype:  v => {
          if(v){
            var ext = v.name.split('.').pop();
            return ["xlsx"].includes(ext) || 'Please upload .xlsx file only';
          } else {
            return 'This field is required'
          }
        }
        // excel type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    },
    options: { 
        page: 1,
        itemsPerPage: 25,
        sortBy: ['id'],
        sortDesc: [true],
        multiSort: true,
        mustSort: true,
    },
  }),
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  methods: {
    ...mapActions(['genCan','checkImportInstoreFile','getInstoreImportOrder','checkImportTaskStatus','confirmInstoreImport','getAllOrderlistInstore','getAllRiderPhonelistInstore','updateOrderInstore']),
    prepareStoreData : async function() {
        let orderList = await this.getAllOrderlistInstore();
        let phoneList = await this.getAllRiderPhonelistInstore();
        this.phone_lists = phoneList
        this.order_lists = orderList.filter(item => { return item.reconciled_order == null})
    },
    async checkImportFile() {
        this.checkInterval = ''
        this.check_status = null
        this.stateimport = false
        this.importItem.import_file_error = "";
        this.importItem.import_content = [];
        this.importItem.is_import_file_valid = false;

        this.importCheckResult = await this.checkImportInstoreFile(this.importItem);

        this.importItem.is_import_file_valid = this.importCheckResult.is_complete;
        this.importItem.import_file_error = this.importCheckResult.message;
        this.import_id = this.importCheckResult.task_id;

        if (this.importCheckResult.is_complete){
          this.importItem.import_content = this.importCheckResult.import_content;

          for(let item in this.importCheckResult.import_content){
            this.importCheckResult.import_content[item].record_case_name = this.mapStatus(this.importCheckResult.import_content[item].record_case)
            let ordertime = ''

            if(this.importCheckResult.import_content[item].file_data['order time']){
              let order_hour = Math.floor(Math.round(this.importCheckResult.import_content[item].file_data['order time'] * 1440) / 60) + ''
              let order_time = (Math.round(this.importCheckResult.import_content[item].file_data['order time'] * 1440) % 60) + ''
              ordertime = this.importCheckResult.import_content[item].file_data.date + ' ' + order_hour + ':' + order_time
            }

            this.importCheckResult.import_content[item].file_data['order time'] = ordertime
          }

          this.instore_datas = this.importCheckResult.import_content.map( d => {
            return {
              ...d,
              driver_phone: d.file_data["edit phone driver"] ? d.file_data["edit phone driver"] : d.file_data["phone driver"],
            }
          });

          this.checkInterval = setInterval(async () => {
            if(this.check_status != 'PREVIEW_COMPLETE'){
              this.check_status = await this.checkImportTaskStatus(this.importCheckResult.task_id)
              let importorder = (await this.getInstoreImportOrder(this.importCheckResult.task_id)).import_content

              for(let item in importorder){
                importorder[item].record_case_name = this.mapStatus(importorder[item].record_case)
                let ordertime = ''

                if(importorder[item].file_data['order time']){
                  let order_hour = Math.floor(Math.round(importorder[item].file_data['order time'] * 1440) / 60) + ''
                  let order_time = (Math.round(importorder[item].file_data['order time'] * 1440) % 60) + ''
                  ordertime = importorder[item].file_data.date + ' ' + order_hour + ':' + order_time
                }

                importorder[item].file_data['order time'] = ordertime
              }

              this.instore_datas = importorder.map( d => {
                return {
                  ...d,
                  driver_phone: d.file_data["edit phone driver"] ? d.file_data["edit phone driver"] : d.file_data["phone driver"],
                }
              });

              console.log(this.check_status)
            }else{
              let importorder2 = (await this.getInstoreImportOrder(this.importCheckResult.task_id)).import_content
              let orderNoList = importorder2.filter(item => { return item.match_order_no != null ? item.match_order_no.order_no : ''}).map(items => {return items.match_order_no.order_no})
              this.order_lists = this.order_lists.filter(function( obj ) {
                return !orderNoList.includes(obj.order_no);
              });

              this.btn_confirm = false;
              this.stateimport = true;
              return true
            }
          }, 5000);

        }
    },

    mapStatus(record_id) {
      let status = '';
      switch (record_id) {
        case 1: status = 'พร้อมนำเข้าข้อมูล'
          break;
        case 2: status = 'Rider มีข้อมูลใน Order แล้ว'
          break;
        case 3: status = 'มีข้อมูล Rider ใน Order แล้ว แต่ไม่ตรงกับในไฟล์ที่ Import'
          break;
        case 4: status = 'ใน DM ไม่มีข้อมูล Rider และไม่สามารถหา Rider จากข้อมูลใน ไฟล์ Import ได้'
          break;
        case 5: status = 'ใน DM มีข้อมูล Rider แต่ไม่สามารถหา Rider จากข้อมูลในไฟล์ Import ได้'
          break;
        case 6: status = 'ไม่พบข้อมูล Order'
          break;
        case 7: status = 'ไม่พบข้อมูล Order และ Rider'
          break;
        case 8: status = 'ข้อมูลจากไฟล์มีไม่ครบ'
          break;
        case 9: status = 'Order ทำจ่ายเงินไปแล้ว'
          break;
        case 10: status = 'ข้อมูลถูกแก้ไข'
          break;
        case -1: status = 'กำลังประมวลผล'
          break;
        default: status = '-'
          break;
      }

      return status;
    },

    async saveInstoreData() {
      let findCase = this.instore_datas.find((element) => element.record_case == 3)
      let text = ''
      if(findCase){
        text = findCase.match_order_no.order_no + ' จะถูกแทนที่ด้วย Driver คนจากไฟล์ที่ Import'
      }else{
        text = 'คุณต้องการบันทึกใช่หรือไม่?'
      }

      if (confirm(text)){
        let confirmImport = await this.confirmInstoreImport(this.import_id);
        if(confirmImport.is_error == false && confirmImport.message == 'success'){
          alert('บันทึกข้อมูลเรียบร้อยแล้ว');
          window.location.reload()
        }
      }

    },

    updateInstoreImport(item) {
      this.update_instore.selected_order = '';
      this.update_instore.order_id = item.id
      this.instore_selected.store_name = item.file_data['store name']
      this.instore_selected.driver = item.file_data.driver
      this.instore_selected.order_time = item.file_data.date +' '+ item.file_data['order time']
      this.instore_selected.phone_cust = item.file_data['phone customer']
      this.instore_selected.id = item.file_data.id
      this.instore_selected.phonenumber = item.file_data['phone driver']

      this.dialog = true
    },

    close (){
      this.searchInput = ''
      this.searchInputRiderPhone = ''
      this.dialog = false;
      setTimeout(() => {
          this.update_instore = _.cloneDeep(this.default_update_instore)
      }, 300)
    },
    async saveUpdateInstore (){
        let updateInstore = await this.updateOrderInstore(this.update_instore);
        if(updateInstore.is_error == false){
          alert('บันทึกข้อมูลเรียบร้อยแล้ว');
          this.close()

          let importorder = (await this.getInstoreImportOrder(this.importCheckResult.task_id)).import_content

          for(let item in importorder){
            importorder[item].record_case_name = this.mapStatus(importorder[item].record_case)
            let ordertime = ''

            if(importorder[item].file_data['order time']){
              let order_hour = Math.floor(Math.round(importorder[item].file_data['order time'] * 1440) / 60) + ''
              let order_time = (Math.round(importorder[item].file_data['order time'] * 1440) % 60) + ''
              ordertime = importorder[item].file_data.date + ' ' + order_hour + ':' + order_time
            }

            importorder[item].file_data['order time'] = ordertime
          }
          this.instore_datas = importorder.map( d => {
            return {
              ...d,
              driver_phone: d.file_data["edit phone driver"] ? d.file_data["edit phone driver"] : d.file_data["phone driver"],
            }
          });

          let orderNoList = importorder.filter(item => { return item.match_order_no != null ? item.match_order_no.order_no : ''}).map(items => {return items.match_order_no.order_no})
          this.order_lists = this.order_lists.filter(function( obj ) {
            return !orderNoList.includes(obj.order_no);
          });

        }else{
          this.editOrder = updateInstore.message
        }
    },

  },
  destoyed: function(){
    clearInterval( this.checkInterval )
  },
  computed: {
    ...mapGetters(['canDo','loadingDataTable']),
    dataLisings() { 
        var res = [];
        return  res;
    },
    headers(){
      return [
        {text: "Id", value: 'id',align: 'center',width: '150px',sortable:false},
        {text: "Store Name", value: 'file_data.store name', align: 'center',width: '120px'},
        {text: "Order Time", value: 'file_data.order time', align: 'left',width: '120px',sortable: false},
        {text: "Customer Phone", value: 'file_data.phone customer', align: 'left',width: '150px'},
        {text: "Driver", value: 'driver', align: 'center',width: '120px'},
        {text: "Rider Phone", value: 'driver_phone', align: 'left',width: '150px'},
        {text: "Order No.", value: 'match_order_no.order_no', align: 'center',width: '150px',sortable:false},
        {text: "Rider No ใหม่", value: 'match_employee.employee_no', align: 'center',width: '120px',sortable:false},
        {text: "Rider No เดิม", value: 'last_employee.employee_no', align: 'center',width: '120px',sortable:false},
        {text: "Status", value: 'record_case_name', align: 'right',width: '100px'},
        {text: "Updated Date", value: 'updatedAt', align: 'center',width: '150px'},
        {text: "", value: 'action', sortable: false, align: 'center', width: '50px'},
      ]
    },
  },
  async beforeMount() {
    await this.prepareStoreData();
  },
  mounted() {
    this.genCan();
  }
};
</script>
<style scoped>
.v-data-table {
  max-width: 100%
}
</style>